/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  return (
    <>
      {/* <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Dashboard'
        fontIcon='bi-app-indicator'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Features</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/features/merchant-management'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Merchant'
        fontIcon='bi-shop-window'
      />
      <SidebarMenuItem
        to='/features/transaction'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Transaction'
        fontIcon='bi bi-receipt'
      />
      <SidebarMenuItem
        to='/features/refund'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Refund Request'
        fontIcon='bi bi-ticket-perforated'
      />
      {/* <SidebarMenuItem
        to='/features/user-management'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User'
        fontIcon='bi-people'
      /> */}
      <SidebarMenuItemWithSub
        to='/features/finance'
        title='Finance'
        fontIcon='bi-bank'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem
          to='/features/finance/withdrawl-request'
          title='Withdrawl Request'
          hasBullet
        />
        <SidebarMenuItem to='/features/finance/report' title='Report' hasBullet />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Configs</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/configs/venue/hottest-venue'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Hottest Venue'
        fontIcon='bi-info'
      />
      <SidebarMenuItem
        to='/configs/event-management/event-list'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Event'
        fontIcon='bi-megaphone'
      />
      <SidebarMenuItem
        to='/configs/banner-management/banner-list'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Banner'
        fontIcon='bi-chat-square-quote'
      />
      <SidebarMenuItemWithSub
        to='/configs/static-data'
        title='Static Data'
        fontIcon='bi bi-box-seam'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/configs/static-data/banks' title='Bank' hasBullet />
        <SidebarMenuItem to='/configs/static-data/category' title='Category' hasBullet />
        <SidebarMenuItem to='/configs/static-data/dress-code' title='Dress Code' hasBullet />
        <SidebarMenuItem to='/configs/static-data/music-genre' title='Music Genre' hasBullet />
        <SidebarMenuItem to='/configs/static-data/offer' title='Offer' hasBullet />
      </SidebarMenuItemWithSub>
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
