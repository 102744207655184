import { createContext, FC, useContext, useEffect, useState } from 'react'
import { WithChildren } from '../../../../../../_metronic/helpers'
import { StaticData, StaticDataDTO } from '../../../../common/core/_models'
import { deleteStaticData, getStaticData, putStaticData } from '../../../../common/core/_requests'
import { postStaticData } from '../../../../common/core/_requests'

type PageProps = {
  type: 'category' | 'dress-code' | 'music-genre' | 'offer' | 'banks'
  title: string
}

type StaticDataListProps = {
  loading: boolean;
  pageProps: PageProps;
  data: StaticData[];
  totalPage: number;
  page: number;
  showFormStaticData: {
    data?: StaticDataDTO;
    visible: boolean;
  },
  onGetStaticData: (v: PageProps) => void;
  onSetPageProps: (v: PageProps) => void;
  onNext: () => void;
  onPrev: () => void;
  onEdit: (data: StaticDataDTO) => Promise<void>;
  onDelete: (data: StaticDataDTO) => Promise<void>;
  onAdd: (data: StaticDataDTO) => Promise<void>;
  setFormStaticData: (visible: boolean, data?: StaticDataDTO) => void;
}

const StaticDataListContext = createContext<StaticDataListProps>({
  loading: false,
  data: [],
  pageProps: {
    title: 'Bank',
    type: 'banks',
  },
  totalPage: 0,
  page: 1,
  showFormStaticData: {
    visible: false,
    data: undefined,
  },
  onGetStaticData: () => { },
  onSetPageProps: () => { },
  onNext: () => { },
  onPrev: () => { },
  onEdit: async () => { },
  onDelete: async () => { },
  onAdd: async () => { },
  setFormStaticData: () => { },
})

const StaticDataListProvider: FC<WithChildren> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [pageProps, setPageProps] = useState<PageProps>({
    title: 'Bank',
    type: 'banks',
  })
  const [data, setData] = useState<StaticData[]>([])
  const [totalPage, setTotalPage] = useState<number>(0)
  const [page, setPage] = useState(6)
  const [allData, setAllData] = useState<StaticData[]>([])
  const [showFormStaticData, setFormStaticData] = useState<{
    data?: StaticDataDTO;
    visible: boolean;
  }>({
    visible: false,
    data: undefined,
  })

  useEffect(() => {
    if (allData) {
      const temp = [...allData]

      setData(temp.slice(Math.abs(6 - page), page))
    }
  }, [page, allData])

  const onSetPageProps = (v: PageProps) => setPageProps(v)
  const onGetStaticData = async (v: PageProps) => {
    try {
      setLoading(true)

      const response = await getStaticData({
        type: v.type,
      })
      const listData = response.data
      setAllData(listData)
      setData(listData)

      if (listData.length > 6) {
        setTotalPage(Math.round(listData.length / 6))
        setData(listData.splice(0, 6))
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onEdit = async (data: StaticDataDTO) => {
    return putStaticData({ type: pageProps.type }, data).then(res => onGetStaticData(pageProps))
  };

  const onDelete = async (data: StaticDataDTO) => {
    return deleteStaticData({ type: pageProps.type }, data).then(res => onGetStaticData(pageProps))
  };

  const onAdd = async (data: StaticDataDTO) => {
    return postStaticData({ type: pageProps.type }, data).then(res => onGetStaticData(pageProps));
  };

  const onNext = () => setPage(page + 6)
  const onPrev = () => setPage(page - 6)

  return (
    <StaticDataListContext.Provider
      value={{
        loading,
        pageProps,
        data,
        page: Math.floor(page / 6),
        totalPage,
        showFormStaticData,
        setFormStaticData: (visible, data) => setFormStaticData({ visible, data }),
        onSetPageProps,
        onGetStaticData,
        onNext,
        onPrev,
        onEdit,
        onDelete,
        onAdd,
      }}
    >
      {children}
    </StaticDataListContext.Provider>
  )
}

const useStaticDataList = () => useContext(StaticDataListContext)

export { StaticDataListProvider, useStaticDataList }
