import { FC } from 'react'
import { StaticData } from '../../../../../common/core/_models'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useStaticDataList } from '../../../static-data-list/core/StaticDataListProvider'

type StaticDataListCustomRowProps = {
  item: StaticData
  index: number
}

const StaticDataListCustomRow: FC<StaticDataListCustomRowProps> = ({ item: { name, id }, index }) => {

  const { onDelete, setFormStaticData, pageProps } = useStaticDataList();

  


  return (
    <tr>
      <td>{`#${id}`}</td>
      <td>{name}</td>
      {pageProps.type !== 'banks' && (
        <td className='text-end min-w-100px'>
          <p
            className='btn btn-light btn-active-light-primary btn-sm'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            Actions
            <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
          </p>
          {/* begin::Menu */}
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
            data-kt-menu='true'
          >
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <p
                className='menu-link px-3'
                onClick={() => {
                  setFormStaticData(true, { id, name });
                }}
              >
                Edit
              </p>
            </div>
            {/* end::Menu item */}

            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <p className='menu-link px-3' data-kt-users-table-filter='delete_row' onClick={() => onDelete({ id, name })}>
                Delete
              </p>
            </div>
            {/* end::Menu item */}
          </div>
          {/* end::Menu */}
        </td>
      )}
    </tr>
  )
}

export { StaticDataListCustomRow }
