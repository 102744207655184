import {FC, useMemo} from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import StaticDataList from './static-data-list/StaticDataList'

type StaticDataProps = {
  type: 'category' | 'dress-code' | 'music-genre' | 'offer' | 'banks'
  title: string
}

const StaticDataPage: FC<StaticDataProps> = ({type, title}) => {
  const breadcrumbs = useMemo(() => {
    const result: Array<PageLink> = [
      {
        title: title,
        path: `/configs/static-data/${type}/static-data-list`,
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]

    return result
  }, [type, title])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path={`static-data-list`}
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>{title}</PageTitle>
              <StaticDataList type={type} title={title} />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to={`/configs/static-data/${type}/static-data-list`} />} />
    </Routes>
  )
}

export default StaticDataPage
