import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'

import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import StaticDataPage from '../modules/configs/static-data/StaticDataPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const FinancePage = lazy(() => import('../modules/features/finance/FinancePage'))
  const MerchantManagementPage = lazy(
    () => import('../modules/features/merchant-management/MerchantManagementPage')
  )
  const BannerPage = lazy(() => import('../modules/configs/banner-management/BannerPage'))
  const EventPage = lazy(() => import('../modules/configs/event-management/EventPage'))
  const VenuePage = lazy(() => import('../modules/configs/venue/VenuePage'))
  const TransactionPage = lazy(() => import('../modules/features/transaction/TransactionPage'))
  const RefundPage = lazy(() => import('../modules/features/refund/RefundPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/features/merchant-management/merchant' />} />
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='features/finance/*'
          element={
            <SuspensedView>
              <FinancePage />
            </SuspensedView>
          }
        />
        <Route
          path='features/merchant-management/*'
          element={
            <SuspensedView>
              <MerchantManagementPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='features/user-management/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='configs/banner-management/*'
          element={
            <SuspensedView>
              <BannerPage />
            </SuspensedView>
          }
        />
        <Route
          path='configs/event-management/*'
          element={
            <SuspensedView>
              <EventPage />
            </SuspensedView>
          }
        />
        <Route
          path='configs/venue/*'
          element={
            <SuspensedView>
              <VenuePage />
            </SuspensedView>
          }
        />
        <Route
          path='configs/static-data/banks/*'
          element={
            <SuspensedView>
              <StaticDataPage type='banks' title='Bank' />
            </SuspensedView>
          }
        />
        <Route
          path='configs/static-data/category/*'
          element={
            <SuspensedView>
              <StaticDataPage type='category' title='Category' />
            </SuspensedView>
          }
        />
        <Route
          path='configs/static-data/dress-code/*'
          element={
            <SuspensedView>
              <StaticDataPage type='dress-code' title='Dress Code' />
            </SuspensedView>
          }
        />
        <Route
          path='configs/static-data/music-genre/*'
          element={
            <SuspensedView>
              <StaticDataPage type='music-genre' title='Music Genre' />
            </SuspensedView>
          }
        />
        <Route
          path='configs/static-data/offer/*'
          element={
            <SuspensedView>
              <StaticDataPage type='offer' title='Offer' />
            </SuspensedView>
          }
        />
        <Route
          path='features/transaction/*'
          element={
            <SuspensedView>
              <TransactionPage />
            </SuspensedView>
          }
        />
        <Route
          path='features/refund/*'
          element={
            <SuspensedView>
              <RefundPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
