import { useStaticDataList } from '../../static-data-list/core/StaticDataListProvider'
import {StaticDataListSearchComponent} from './StaticDataListSearchComponent'
import { StaticDataListToolbar } from './StaticDataListToolbar'

const StaticDataListHeader = () => {

  const {pageProps} = useStaticDataList();

  return (
    <div className='card-header border-0 pt-6'>
      <StaticDataListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {pageProps.type !== 'banks' && <StaticDataListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {StaticDataListHeader}
