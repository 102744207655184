import { KTSVG } from '../../../../../../_metronic/helpers';
import { useStaticDataList } from '../../static-data-list/core/StaticDataListProvider';

const StaticDataListToolbar = () => {

  const { setFormStaticData, pageProps } = useStaticDataList();

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={() => setFormStaticData(true, undefined)}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add {pageProps.title}
      </button>
      {/* end::Add user */}
    </div>
  )
}

export { StaticDataListToolbar }
