import axios from 'axios'
import {StaticData, StaticDataDTO} from './_models'

export const STATIC_DATA_URL = '/api/static-data' // /api/static-data/{type}

export type staticDataProps = {
  type: 'category' | 'dress-code' | 'music-genre' | 'offer' | 'banks'
}

export function getStaticData(data: staticDataProps) {
  return axios.get<StaticData[]>(`${STATIC_DATA_URL}/${data.type}`)
}

export function postStaticData(type: staticDataProps, data: StaticDataDTO) {
  return axios.post(`${STATIC_DATA_URL}/${type.type}`, data)
}

export function deleteStaticData(type: staticDataProps, data: StaticDataDTO) {
  return axios.delete(`${STATIC_DATA_URL}/${type.type}/${data.id}`)
}

export function putStaticData(type: staticDataProps, data: StaticDataDTO) {
  return axios.put(`${STATIC_DATA_URL}/${type.type}`, data)
}
