import {KTSVG} from '../../../../../../_metronic/helpers'
import {useStaticDataList} from '../../static-data-list/core/StaticDataListProvider'

const StaticDataListSearchComponent = () => {
  const {pageProps} = useStaticDataList()

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder={`Search ${pageProps.title}`}
          onChange={(e) => {
            console.log(e)
          }}
        />
      </div>
      {/* end::Search */}
    </div>
  )
}

export {StaticDataListSearchComponent}
