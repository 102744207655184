import { KTCardBody } from '../../../../../../_metronic/helpers'
import { useStaticDataList } from '../../static-data-list/core/StaticDataListProvider'
import { StaticDataListCustomRow } from './columns/StaticDataListCustomRow'

const StaticDataListTable = () => {
  const { pageProps, data, onNext, onPrev, totalPage, page } = useStaticDataList()

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th>No</th>
              <th>{pageProps.title}</th>
              {pageProps.type !== 'banks' && (
                <th className='text-end min-w-100px'>Action</th>
              )}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {data.map((v, index) => (
              <StaticDataListCustomRow key={`row-${v}-(${index})`} index={index + 1} item={v} />
            ))}
          </tbody>
        </table>
      </div>

      {totalPage > 1 && (
        <div className='row' style={{ marginTop: 20 }}>
          <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
          <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
            <div id='kt_table_users_paginate'>
              <ul className='pagination'>
                <li
                  className={`page-item previous ${page === 1 && 'disabled'}`}
                  onClick={() => {
                    if (page < 2) {
                      return
                    }

                    onPrev()
                  }}
                >
                  <p className='page-link'>
                    Previous
                  </p>
                </li>
                <li className='page-item next'>
                  <p className='page-link' onClick={onNext}>
                    Next
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </KTCardBody>
  )
}

export { StaticDataListTable }
