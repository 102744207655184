import { KTSVG } from '../../../../../../_metronic/helpers'
import { useStaticDataList } from '../../static-data-list/core/StaticDataListProvider'

const StaticDataHeader = () => {
  const { showFormStaticData, pageProps, setFormStaticData } = useStaticDataList();

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{showFormStaticData.data ? 'Edit' : 'Create'} {pageProps.title}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setFormStaticData(false, undefined)}
        style={{ cursor: 'pointer' }}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export { StaticDataHeader }
