/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect} from 'react'
import {KTCard} from '../../../../../_metronic/helpers'
import {LoadingIndicator} from '../../../../common/components/LoadingIndicator'
import {StaticDataListHeader} from '../components/header/StaticDataListHeader'
import {StaticDataListTable} from '../components/table/StaticDataListTable'
import {StaticDataListProvider, useStaticDataList} from './core/StaticDataListProvider'
import { CreateStaticData } from '../static-data-create/CreateStaticData'

type StaticDataListProps = {
  type: 'category' | 'dress-code' | 'music-genre' | 'offer' | 'banks'
  title: string
}

const StaticDataList: FC<StaticDataListProps> = (props) => {
  const {loading, onGetStaticData, onSetPageProps, showFormStaticData} = useStaticDataList()

  useEffect(() => {
    onGetStaticData(props)
    onSetPageProps(props)
  }, [props])

  return (
    <KTCard>
      {loading && <LoadingIndicator />}
      <StaticDataListHeader />
      <StaticDataListTable />
      {showFormStaticData.visible && <CreateStaticData />}
    </KTCard>
  )
}

const StaticDataListWrapper: FC<StaticDataListProps> = (props) => (
  <StaticDataListProvider>
    <StaticDataList {...props} />
  </StaticDataListProvider>
)

export default StaticDataListWrapper
